import React from "react"
//import { StaticImage } from "gatsby-plugin-image"

const ComponentPics2 = ({ images }) => {
  return (
    <div className="flex-row mx-n2 mb-6 pict-3">
    {/*
      <div className="px-2">
        <img
          src={images[0]}
          className="mb-4 pics-2-img"
          alt=""
        />
      </div>
      <div className="px-2">
        <img
          src={images[1]}
          className="mb-4 pics-2-img"
          alt=""
        />
      </div>
      <div className="px-2">
        <img
          src={images[2]}
          className="mb-4 pics-2-img"
          alt=""
        />
      </div>
    */}
    </div>
  )
}

export default ComponentPics2
